import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const Dashboard = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const quickLinWidget = ({ title, icon, url }) => {
    return (
      <div className="card quick-link-item">
        <div className={"d-flex justify-content-center"}>
          <div className="round-icon bg-secondary">
            <i className={`${icon} text-white fs-5`}></i>
          </div>
        </div>
        <div className="card-body ">
          <div className={"d-flex justify-content-center"}>
            <a className='q-link' href={url} >
              <span className="fs-6 fw-bold mx-3 quick-link-title">{title}</span>
            </a>

          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header label={<h5 className='py-0 my-0 text-dark font-style-italic'>Your HR World in One Place!</h5>} />
      <main id="main" className="main">

        {/*<div className='row mb-5'>
          <div className='col-lg-12'>
            <div className='homepage-banner rounded'>
            </div>
          </div>
        </div>*/}

        <div className='row'>
          <div className='col-12'>


            <div className='card quick-links-card bg-sky-blue bg-green'>
              {/* <div className='blur shadow-blur'> */}
              <div className='card-body'>
                <div className={"d-flex-column mx-5"}>
                  <span className={"text-black mb-3 quicklink-header-title"}>QUICK LINKS</span>
                  <br /><br /><br />
                  <div className=''>

                    <div className='row'>
                      <div className='col-3 d-flex justify-content-center'>
                        {quickLinWidget({ title: 'Leave Management', icon: 'fa-solid fa-users', url: 'staff-analysis' })}
                      </div>
                      <div className='col-3 d-flex justify-content-center'>
                        {quickLinWidget({ title: 'Recruitment / Vacancies', icon: 'fa-solid fa-user-gear', url: 'staff-analysis' })}

                      </div>

                      <div className='col-3 d-flex justify-content-center'>
                        {quickLinWidget({ title: 'Benefits Calculator & Payslip Simulator', icon: 'fa-solid fa-coins', url: 'staff-analysis' })}
                      </div>
                      <div className='col-3 d-flex justify-content-center'>
                        {quickLinWidget({ title: 'Employee Recognition', icon: 'fa-solid fa-scale-balanced', url: 'staff-analysis' })}
                      </div>
                    </div>
                    <br />
                    <div className='row'>
                      <div className='col-3 d-flex justify-content-center'>
                      {quickLinWidget({ title: 'My Team', icon: 'fa-solid fa-users', url: 'staff-analysis' })}

                      </div>
                      <div className='col-3 d-flex justify-content-center'>
                      {quickLinWidget({ title: 'Directory', icon: 'fa-solid fa-folder', url: 'staff-analysis' })}
                      </div>

                      <div className='col-3 d-flex justify-content-center'>
                      {quickLinWidget({ title: 'Birthdays', icon: 'fa-solid fa-cake-candles', url: 'staff-analysis' })}

                      </div>
                      <div className='col-3 d-flex justify-content-center'>
                      {quickLinWidget({ title: 'Expenses', icon: 'fa-solid fa-money-bill', url: 'staff-analysis' })}

                      </div>
                    </div>

                  </div>
                </div>
                {/* </div> */}
              </div>

            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div class="my-3 d-flex mx-3">
              <div class="me-2"><i class="fa-solid fa-bell text-danger bell-size"></i></div>
              <div class="flex-grow-1 d-flex flex-column">
                <p class="fw-bold my-0">Mandatory Training</p>
              </div>
            </div>
            <div className='mx-3'>
              <p class="my-0">A mandatory training scheduled for you on the LMS: <span className='fw-bold'>Understanding the importance of data security and information privacy.</span></p>
              <p class="py-0">Please <a href="#">click here</a> to access.</p>
              <br/>
            </div>

          </div>
        </div>


        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-header bg-primary text-light fw-bold'>
                Latest News / Communication
              </div>
              <div className='card-body latest-news'>
                <div className='row'>
                  {[
                    // {
                    //   page: "Always-on Surveys",
                    //   description: "Your weekly check-in",
                    //   data: [{name: "09 Oct ’23 : Check-in", status: "Not started", days: 3}],
                    //   color: "text-warning"
                    // },
                    {
                      page: "Spot Surveys",
                      description: "Your feedback on change!",
                      data: [{ name: "New Flexible Work Policy", status: "Complete", days: 9 }],
                      color: "text-success",
                      url: "https://demo-engagement.concisesolutions.co.za",
                    },
                    {
                      page: "Pulse Surveys",
                      description: "Your weekly check-in: 09 October",
                      data: [{ name: "End of year Pulse-check", status: " In Progress", days: 11 }],
                      color: "text-secondary",
                      url: "https://demo-engagement.concisesolutions.co.za",

                    },
                  ].map((item, key) => (
                    <>
                      <div key={key} className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='card'>
                          <div className='card-body p-4'>
                            <div className='my-3 d-flex'>
                              <div className='me-2'>
                                <i className={`fa-solid fa-bell ${item.color} bell-size`}></i>
                              </div>
                              <div className='flex-grow-1 d-flex flex-column'>
                                <p className='fw-bold my-0'>{item.page}</p>
                                <p className=''>{item.description}</p>
                              </div>
                            </div>
                            <div>
                              {item.data.map((element, key) => (
                                <>
                                  <div className='bg-secondary fw-bold p-1 rounded-1  text-dark' > <a href={item.url} className='text-light' target="_blank">{element.name}</a></div>
                                  <div className='my-3'>Status: <span
                                    className={`fw-bold ${item.color}`}>{element.status}</span></div>
                                  <div className='font-style-italic'><i
                                    className="fa-solid fa-circle-info fa-xl text-primary me-3"></i>Closes in <span
                                      className={`fw-bold`}>{element.days}</span> days!
                                  </div>
                                </>
                              ))}

                              {item.data.length == 0 && (
                                <>
                                  <div className='bg-grey-100 fw-bold text-light p-1 rounded-1 border b'>No active survey
                                    available
                                  </div>
                                  <br />
                                  <br />
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>

    </>
  )
}
export default Dashboard;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const DocumentResources = () => {
    const documentsData = [
        {
            category: 'Policy Documents',
            documents: [
              
                { id: 2342, name: 'Anti-Discrimination and Harassment Policy', file: '/files/code_of_conduct.pdf' },
                { id: 2433, name: 'Employee Handbook', file: '/files/code_of_conduct.pdf' },
                { id: 294, name: 'Leave and Attendance Policy', file: '/files/code_of_conduct.pdf' },
                { id: 2121, name: 'Remote Work Policy', file: '/files/code_of_conduct.pdf' },
                { id: 211, name: "Employee Benefits Policy (health insurance, retirement plans, etc.)", file: '/files/code_of_conduct.pdf' },
                { id: 25667, name: 'Performance Management Policy', file: '/files/code_of_conduct.pdf' },
                { id: 2124, name: 'Workplace Health and Safety Policy', file: '/files/code_of_conduct.pdf' },
                { id: 223, name: 'Equal Opportunity Policy', file: '/files/code_of_conduct.pdf' },
                { id: 21, name: 'Social Media and Internet Usage Policy', file: '/files/code_of_conduct.pdf' },

            ]
        },
        {
            category: 'HR Processes',
            documents: [
                
                { id: 16625, name: 'Recruitment and Hiring Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 4445, name: 'Onboarding Process for New Employees', file: '/files/expense_reimbursement_form.pdf' },
                { id: 524533, name: 'Performance Appraisal and Review Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 235, name: 'Employee Training and Development Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 5665, name: 'Leave Request and Approval Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 3445, name: 'Employee Grievance Handling Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 52133, name: 'Promotion and Career Advancement Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 2315, name: 'Compensation and Benefits Enrolment Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 533, name: 'Employee Offboarding or Exit Process', file: '/files/expense_reimbursement_form.pdf' },
                { id: 125, name: 'Workplace Accommodation Request Process', file: '/files/expense_reimbursement_form.pdf' },
            ]
        }
        // Add more categories and documents as needed
    ];

    const handleDownload = (fileUrl) => {
        // Create a virtual anchor element to trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl.split('/').pop(); // Use the last part of the URL as the file name
        link.click();
    };

    return (
        <>
            <Header icon={"fa-solid fa-info fa-xl mx-2"} label={"Help and Support"} />
            <main id="main" className="main">
                <section className="section ">
                    <div className='faq-banner rounded'>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="row">
                                        {documentsData.map(categoryData => (
                                            <div key={categoryData.category} className="col-md-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{categoryData.category}</h5>
                                                        <ul className="list-group list-group-flush">
                                                            {categoryData.documents.map(document => (
                                                                <li key={document.id} className="list-group-item d-flex justify-content-between align-items-center" onClick={() => handleDownload(document.file)}>
                                                                    {document.name}
                                                                    <i className="fas fa-file-download"></i>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default DocumentResources;
